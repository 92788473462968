import {login, loginByToken, logout} from "@/js/login";
import Cookies from "js-cookie";
import {Message} from "element-ui";

const actions = {
  /***
   * 登录
   * @param commit 上下文
   * @param userInfo 登录信息
   * */
  login(context, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo).then(response => {
          const {data, code} = response
          if (code === '0') {
            //提交数据
            context.commit('saveUserInfo', data)
            //存入cookies
            Cookies.set('token', data.token)
            sessionStorage.setItem('token', data.token)
            Message({
              message: '登录成功!',
              type: 'success',
              duration: 3 * 1000
            })
            resolve(true)
          } else {
            Message({
              message: response.msg,
              type: 'error',
              duration: 3 * 1000
            })
            resolve(false)
          }
        }
      ).catch(error => {
        reject(error)
      })
    })
  },

  /**
   * 根据token登录系统
   * @param context
   * @returns {Promise<unknown>}
   */
  loginByToken(context) {
    return new Promise((resolve, reject) => {
      /**
       * 根据token信息登录系统
       */
      loginByToken().then(response => {
          const {data, code} = response
          if (code === '0') {
            //提交数据
            context.commit('saveUserInfo', data)
            //存入cookies
            Cookies.set('token', data.token)
            sessionStorage.setItem('token', data.token)
            resolve(true)
          } else {
            Message({
              message: response.msg,
              type: 'error',
              duration: 3 * 1000
            })
            resolve(false)
          }
        }
      ).catch(error => {
        reject(error)
      })
    })
  },
  /**
   * 退出登录
   * @param context
   * @returns {Promise<unknown>}
   */
  logout(context, cb) {
    //清除vuex
    context.commit('CLEAR_USERINFO')
    sessionStorage.removeItem('token')
    Cookies.remove('token')
    logout().then((res) => {
      if (res.code === '0') {
        console.log('服务端退出成功!')
      } else {
        console.log('服务端退出失败!')
      }
    })
    cb(true)
  }
}
export default actions
