import request from "@/js/request";
import {baseURL} from "@/js/getawayModule";

/**
 * 登录
 * @param data 登录参数
 * @return request
 * */
export function login(data) {
  return request({
    url: `${baseURL.authApi}/login`,
    method: 'post',
    data
  })
}


/**
 * 根据token登录
 * @returns {AxiosPromise}
 */
export function loginByToken() {
  return request({
    url: `${baseURL.authApi}/loginByToken`,
    method: 'get',
  })
}


/**
 * 退出登录
 * */
export function logout(){
  return request({
    url: `${baseURL.authApi}/logout`,
    method: 'get'
  })
}

