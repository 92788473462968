/**
 * 路径
 * */
const srm = 'srm'
const auth = 'auth'
/**
 *
 * 网关模块
 *
 * */
export const getaway = {
  user: '/user/',
  resource: '/resource/',
  oa: '/oa/',
  weixin: '/weixin/'
}

/**
 * 请求路径
 * */
export const baseURL = {
  authApi: getaway.user + auth,
  userApi: getaway.user + srm,
  resource: getaway.resource + srm,
  oa: getaway.oa + srm,
  weixinApi: getaway.weixin + srm
}
