import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router/index.js'
import '@/assets/css/global.css'
import '@/assets/fonts/iconfont.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from '@/js/request.js'
import store from './store'

Vue.prototype.axios = axios
Vue.prototype.getaway = {
  user: '/user/',
  redirect: '/resource/',
  oa: '/oa/'
}
Vue.config.productionTip = false
// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false
// element 全局配置
Vue.use(ElementUI)
Array.prototype.remove = function (val) {
  let index = this.indexOf(val);
  if (index > -1) {
    this.splice(index, 1);
  }
}
Vue.filter('dateFormat', function(originVal) {
  const dt = new Date(originVal)

  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return `${y}-${m}-${d}`
})
// 挂载的路由
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
