//默认缓存的信息
const state = {
  //用户名称
  name: '',
  //用户ID
  userId: '',
  //供应商ID
  srmSupplierId: '',
  //用户性别
  userSex: '',
  //用户票据
  token: '',
  //用户信息
  userInfo: {},
}

export default state
