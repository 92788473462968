const mutations = {
  /**
   * 清空vuex中的用户信息
   * @param state
   * @constructor
   */
  CLEAR_USERINFO: (state) => {
    //清除vuex
    state.name = ''
    state.userId = ''
    state.srmSupplierId = ''
    state.userSex = ''
    state.token = ''
    state.userInfo = {}
  },
  /**
   * 登录成功存储数据
   * @param state
   * @param data
   */
  saveUserInfo(state, data) {
    const userInfo = data.loginUser
    state.name = userInfo.name
    state.userId = userInfo.id
    state.srmSupplierId = userInfo.srmSupplierId
    state.userSex = userInfo.sex
    state.token = data.token
    state.userInfo = userInfo
  }
}
export default mutations
